import React from "react"

import { Table, Header, Body } from "./table"

export const ParametersTable = ({ children }) => {
  return (
    <Table>
      <Header>
        <tr>
          <th>Parameter</th>
          <th>Place</th>
          <th>Required</th>
          <th>Description</th>
        </tr>
      </Header>
      <Body>{children}</Body>
    </Table>
  )
}

export const ParameterRow = ({ name, place, required, description }) => (
  <tr>
    <td>{name}</td>
    <td>{place}</td>
    <td>{required ? "required" : "optional"}</td>
    <td>{description}</td>
  </tr>
)
