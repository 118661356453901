import React from "react"
import { Table, Header, Body, RowHeader } from "./table"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import Code from "@components/code"

function reformatJson(json) {
  return JSON.stringify(JSON.parse(json), undefined, 2)
}

function jsonKeys(json) {
  return Object.keys(JSON.parse(json))
}

const ContentType = styled.div`
  ${props => props.theme.typography.subtitle1};
  margin-bottom: 0.5rem;
`

export const ResponsesTable = ({ children }) => {
  return (
    <Table>
      <Header>
        <tr>
          <RowHeader>Status</RowHeader>
          <RowHeader full>Description</RowHeader>
        </tr>
      </Header>
      <Body>{children}</Body>
    </Table>
  )
}

export const ResponseRow = ({ name, description, content, headers }) => (
  <tr>
    <td
      css={css`
        vertical-align: top;
      `}
    >
      {name}
    </td>
    <td>
      {headers && headers.length > 0 && (
        <div>
          <ContentType>Response in headers</ContentType>
          <Code>{JSON.stringify(headers.map(jsonKeys).flat())}</Code>
        </div>
      )}
      {content && (
        <div>
          <ContentType>{content.contentType}</ContentType>
          <Code>{reformatJson(content.schemaDescription)}</Code>
        </div>
      )}
    </td>
  </tr>
)
