import styled from "@emotion/styled"

export const Table = styled.table`
  width: 100%;
  text-align: left;
  border-collapse: collapse;
  font-family: Open Sans;
  margin-bottom: 1rem;
  border-bottom: 2px solid ${props => props.theme.color.secondary.dark};
`

export const Header = styled.thead`
  tr {
    background-color:  ${props => props.theme.color.secondary.light};
    );
  }
  th {
    padding: 8px;
    padding-bottom: 4px;
    border-bottom: 4px solid ${props => props.theme.color.secondary.dark};
  }
`

export const Body = styled.tbody`
  font-size: 14px;
  tr:nth-of-type(even) {
    background-color: rgba(0, 0, 0, 0.15);
  }
  tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }

  td {
    padding: 8px;
  }
`

export const RowHeader = styled.th`
  width: ${props => (Boolean(props.full) ? `100%` : "inherit")};
  min-width: 150px;
`
