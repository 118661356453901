import styled from "@emotion/styled"

export default styled.pre`
  background-color: ${props => props.theme.color.text.high};
  color: rgba(255, 255, 255, 0.89);
  max-height: 440px;
  max-width: calc(${props => props.theme.media.breakpoints.sm} * 0.6);
  overflow-y: scroll;
  overflow-x: auto;
  padding: 0.5rem;
  line-height: 1.2rem;

  ${props => props.theme.media.devices.desktop} {
    max-width: calc(${props => props.theme.media.breakpoints.md} * 0.6);
  }

  ${props => props.theme.media.devices.largeDesktop} {
    max-width: calc(${props => props.theme.media.breakpoints.md} * 0.8);
  }
`
