import React from "react"
import styled from "@emotion/styled"

const Verb = styled.span`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.54);
`

const Path = styled.span`
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
  padding-left: 1rem;
`

const Root = styled.div`
  margin-bottom: 1rem;
`

export default ({ verb, path }) => (
  <Root>
    <Verb>{verb}</Verb>
    <Path>{path}</Path>
  </Root>
)
