import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import Link from "@components/link"

const Content = styled.div`
  padding: 0.5rem;
  ${props => props.selected && `font-weight: bold;`}
`

const PathLink = ({ selected, text, to }) => (
  <Link selected={selected} to={to} display={"block"}>
    <Content selected={selected}>{text}</Content>
  </Link>
)

PathLink.propTypes = {
  selected: PropTypes.bool,
  to: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}

export default PathLink
