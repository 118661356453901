import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import PathLink from "./pathLink"
import Columns from "./columns"
import { css } from "@emotion/react"

const pathWeight = (verb, name) =>
  verbWeights.indexOf(verb.toLowerCase()) * 10000 + name.length

const pathSorter = (a, b) =>
  pathWeight(a.verb, a.name) > pathWeight(b.verb, b.name)

const verbWeights = [
  "get",
  "post",
  "put",
  "delete",
  "head",
  "connect",
  "options",
  "patch",
]

const Box = styled.div`
  background-color: rgba(0, 0, 0, 0.06);
  padding: 8px;
  border-top: solid 2px rgba(0, 0, 0, 0.56);
  margin-bottom: 1rem;
`

const TocLabel = styled.div`
  fill: rgba(0, 0, 0, 0.67);
  color: rgba(0, 0, 0, 0.67);
`

const Rotator = styled.span(
  ({ active }) => `
    svg, div{
        transform: rotate(${active ? 0 : 180}deg);
        transform-origin: center center;
        transition: transform 300ms;
        width: 0.5em;
        vertical-align: middle;
    }
`
)

const Arrow = () => (
  <svg
    width="34"
    height="20"
    viewBox="0 0 34 20"
    css={css`
      padding: 4px;
    `}
  >
    <path d="M17 12.758L5.121.879a2.998 2.998 0 0 0-4.242 0 2.998 2.998 0 0 0 0 4.242l14 14a2.998 2.998 0 0 0 4.242 0l14-14C33.707 4.535 34 3.768 34 3s-.293-1.535-.879-2.121a2.998 2.998 0 0 0-4.242 0L17 12.758z"></path>
  </svg>
)

const ApiReferenceList = ({
  apiPaths,
  selected,
  disableCollapse,
  defaultCollapse,
}) => {
  const items = apiPaths.sort(pathSorter).map(({ href, name, verb }) => {
    return (
      <PathLink
        key={href}
        selected={selected === href}
        to={href}
        text={`${verb.toUpperCase()} ${name}`}
      />
    )
  })

  const [collapsed, setCollapsed] = useState(defaultCollapse || false)

  const toggleCollapse = () => {
    setCollapsed(!collapsed)
  }

  return (
    <Box>
      <TocLabel onClick={toggleCollapse}>
        Api reference content
        {!disableCollapse && (
          <Rotator active={collapsed}>
            <Arrow />
          </Rotator>
        )}
      </TocLabel>

      {(!collapsed || disableCollapse) && <Columns>{items}</Columns>}
    </Box>
  )
}

ApiReferenceList.propTypes = {
  apiPaths: PropTypes.arrayOf({
    to: PropTypes.string.isRequired,
    verb: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
}

export default ApiReferenceList
