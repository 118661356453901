import React from "react"
import { graphql } from "gatsby"
import Layout from "@components/layout"
import Title from "@components/title"
import PathHeader from "./pathHeader"
import styled from "@emotion/styled"
import PathDescription from "./pathDescription"
import ApiReferenceList from "./apiReferenceList"
import { ParameterRow, ParametersTable } from "./parameterTable"
import { ResponseRow, ResponsesTable } from "./responsesTable"
import {
  RequestBodyRow,
  RequestBodysTable,
  SensorsIoTable,
} from "./requestBodyTable"

const ParametersSection = ({ parameters }) => {
  if (!parameters || parameters.length === 0) return null

  return (
    <>
      <Subtitle>Parameters</Subtitle>
      <ParametersTable>
        {parameters.map(p => (
          <ParameterRow
            key={p.name}
            name={p.name}
            place={p.in}
            required={p.required}
            description={p.description}
          />
        ))}
      </ParametersTable>
    </>
  )
}

const ResponsesSection = ({ responses }) => {
  if (!responses || responses.length === 0) return null

  return (
    <>
      <Subtitle>Responses</Subtitle>
      <ResponsesTable>
        {responses.map(r => (
          <ResponseRow
            key={r.status}
            name={r.status}
            description={r.description}
            headers={r.headers}
            content={r.content[0]}
          ></ResponseRow>
        ))}
      </ResponsesTable>
    </>
  )
}

const RequestBodySection = ({ requestBody }) => {
  if (!requestBody || requestBody.length === 0) return null
  return (
    <>
      <Subtitle>Request body</Subtitle>
      <RequestBodysTable>
        {requestBody.map(rb => (
          <RequestBodyRow
            contentType={rb.contentType}
            schema={rb.schemaDescription}
          />
        ))}
      </RequestBodysTable>
    </>
  )
}
const RequestBodyPostEventSection = ({ requestBody }) => {
  if (!requestBody || requestBody.length === 0) return null
  return (
    <>
      <Subtitle>Request body</Subtitle>
      <RequestBodysTable>
        {requestBody.map(rb => (
          <RequestBodyRow
            contentType={rb.contentType}
            schema={rb.schemaDescription}
          />
        ))}
      </RequestBodysTable>
      <Subtitle>Sensors IO</Subtitle>
      <p>
        In the case of the Aip-IO, the sensors range from 0 to 15, following the
        next relationship:
      </p>
      <br></br>
      <SensorsIoTable>
        <tr>
          <td>0/0</td>
          <td>0</td>
        </tr>
        <tr>
          <td>0/1</td>
          <td>1</td>
        </tr>
        <tr>
          <td>0/2</td>
          <td>2</td>
        </tr>
        <tr>
          <td>0/3</td>
          <td>3</td>
        </tr>
        <tr>
          <td>1/0</td>
          <td>4</td>
        </tr>
        <tr>
          <td>1/1</td>
          <td>5</td>
        </tr>
        <tr>
          <td>1/2</td>
          <td>6</td>
        </tr>
        <tr>
          <td>1/3</td>
          <td>7</td>
        </tr>
        <tr>
          <td>2/0</td>
          <td>8</td>
        </tr>
        <tr>
          <td>2/1</td>
          <td>9</td>
        </tr>
        <tr>
          <td>2/2</td>
          <td>10</td>
        </tr>
        <tr>
          <td>2/3</td>
          <td>11</td>
        </tr>
        <tr>
          <td>3/0</td>
          <td>12</td>
        </tr>
        <tr>
          <td>3/1</td>
          <td>13</td>
        </tr>
        <tr>
          <td>3/2</td>
          <td>14</td>
        </tr>
        <tr>
          <td>3/3</td>
          <td>15</td>
        </tr>
      </SensorsIoTable>
    </>
  )
}

const Subtitle = styled.h2`
  ${props => props.theme.typography.headline6};
  margin-bottom: 0.5rem;
`

export default function Template(props) {
  const { data, pageContext } = props
  const { pathInfo, tagPaths } = data

  const paths = tagPaths.nodes.map(({ verb, name, slug }) => ({
    href: `/reference/${slug}`,
    verb,
    name,
  }))
  if (
    pathInfo.name.includes("/api/events") &
    ((pathInfo.verb === "post") | (pathInfo.verb === "put"))
  ) {
    return (
      <Layout>
        <>
          <Title>{pageContext.title}</Title>
          <ApiReferenceList
            apiPaths={paths}
            selected={`/reference/${pathInfo.slug}`}
            disableCollapse={!pathInfo}
            defaultCollapse={pathInfo}
          />

          <PathHeader
            key={pathInfo.name}
            verb={pathInfo.verb}
            path={pathInfo.name}
          />
          {pathInfo.description && (
            <PathDescription>{pathInfo.description}</PathDescription>
          )}

          <ParametersSection parameters={pathInfo.parameters} />

          <RequestBodyPostEventSection requestBody={pathInfo.requestBody} />

          <ResponsesSection responses={pathInfo.responses} />
        </>
      </Layout>
    )
  } else {
    return (
      <Layout>
        <>
          <Title>{pageContext.title}</Title>
          <ApiReferenceList
            apiPaths={paths}
            selected={`/reference/${pathInfo.slug}`}
            disableCollapse={!pathInfo}
            defaultCollapse={pathInfo}
          />

          <PathHeader
            key={pathInfo.name}
            verb={pathInfo.verb}
            path={pathInfo.name}
          />
          {pathInfo.description && (
            <PathDescription>{pathInfo.description}</PathDescription>
          )}

          <ParametersSection parameters={pathInfo.parameters} />

          <RequestBodySection requestBody={pathInfo.requestBody} />

          <ResponsesSection responses={pathInfo.responses} />
        </>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query($tag: String, $id: String = "") {
    pathInfo: oasPath(id: { eq: $id }) {
      name
      verb
      description
      parameters {
        name
        in
        description
        required
        schema {
          format
          type
        }
      }
      responses {
        status
        description
        headers
        content {
          contentType
          schemaDescription
        }
      }
      requestBody {
        contentType
        schemaDescription
      }
    }
    tagPaths: allOasPath(filter: { mainTag: { eq: $tag } }) {
      nodes {
        verb
        name
        mainTag
        slug
      }
    }
  }
`
