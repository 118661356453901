import React from "react"
import styled from "@emotion/styled"

const Root = styled.div({
  display: "flex",
  flexFlow: "row wrap",
})

const Column = styled.div(({ theme }) => ({
  width: "100%",
  [theme.media.devices.largeDesktop]: {
    width: "50%",
  },
}))

const Columns = ({ children }) => {
  const first = children.filter((_, idx) => idx < children.length / 2.0)
  const second = children.filter((_, idx) => idx >= children.length / 2.0)

  return (
    <Root>
      <Column>{first}</Column>
      <Column>{second}</Column>
    </Root>
  )
}

export default Columns
