import React from "react"
import { Table, Header, Body, RowHeader } from "./table"
import { css } from "@emotion/react"
import Code from "@components/code"

export const RequestBodysTable = ({ children }) => {
  return (
    <Table>
      <Header>
        <tr>
          <RowHeader>Content type</RowHeader>
          <RowHeader full>Schema</RowHeader>
        </tr>
      </Header>
      <Body>{children}</Body>
    </Table>
  )
}

export const SensorsIoTable = ({ children }) => {
  return (
    <Table>
      <Header>
        <tr>
          <RowHeader>Physical sensor</RowHeader>
          <RowHeader>Api number</RowHeader>
        </tr>
      </Header>
      <Body>{children}</Body>
    </Table>
  )
}

const reformatJson = json => JSON.stringify(JSON.parse(json), undefined, 2)

export const RequestBodyRow = ({ contentType, schema }) => (
  <tr>
    <td
      css={css`
        vertical-align: top;
      `}
    >
      {contentType}
    </td>
    <td>
      <Code>{reformatJson(schema)}</Code>
    </td>
  </tr>
)
